import * as React from "react";
import * as leaflet from "leaflet";
import { 
  Map as RLMap, 
  TileLayer, 
  CircleMarker, 
  Popup, 
  Tooltip
} from "react-leaflet";

class Map extends React.Component<any, any> {

  getRadius (totalStudents) {
    let radius = totalStudents * .5 + 5;
    return radius
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.locations !== this.props.locations) {
      this.props.mapHasLoaded();
    }
  }

  render() {

    const { locations, mapHasLoaded } = this.props;

    const southwest = leaflet.latLng(-90, -180);
    const northeast = leaflet.latLng(90, 180);
    return (
      <RLMap 
        center={[36, -100]} 
        zoom={5}
        minZoom={3} 
        maxBounds={leaflet.latLngBounds(southwest, northeast)}
        maxBoundsViscosity={.4}
        style={{width: "100%", height: "100%"}}>
        <TileLayer
          url={`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
          id="mapbox/streets-v11"
          tileSize={512}
          zoomOffset={-1}
          accessToken={process.env.REACT_APP_MAPBOX_TOKEN}/>
          {Object.keys(locations).map((key) => {
            const location = locations[key];
            let totalStudents = location.totalStudents;
            const radius = this.getRadius(totalStudents);
            // Do not display markers on the default 0,0 coords (comes from missing zip codes)
            const validCoords = location.coords[0] !== "0.00" && location.coords[1] !== "0.00";

            if (location.inStudentRange && location.inProgramRange && location.totalStudents > 0 && validCoords) {
              return (
                <CircleMarker 
                  color='#CF4520' 
                  center={location.coords} 
                  radius={radius}
                  key={key}
                  onMouseOver={(e) => e.target.setRadius(radius + 2)}
                  weight={1.5}
                  onMouseOut={(e) => e.target.setRadius(radius)}>
                  <Tooltip direction="top" offset={[0, -(radius + 6)]}>{totalStudents}</Tooltip>
                  <Popup>
                    <div className="total-students">Total Students: {totalStudents}</div>
                    <div className="table-container">
                      <table>
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Program Name</th>
                            <th>Students</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(location.programs).map((programId, key) => {
                            const program = location.programs[programId];
                            if (program.checked) {
                              return(
                                <tr key={key}>
                                  <td>{program.id}</td>
                                  <td>{program.name}</td>
                                  <td>{program.totalStudents}</td>
                                </tr>
                              );
                            }
                          })}
                        </tbody>
                      </table>
                    </div>
                  </Popup>
                </CircleMarker>
              );
            }
            else {
              return null;
            }
          })
          }
      </RLMap>
    );
  }
}

export default Map;
