import React from 'react';
import ReactDOM from 'react-dom';
import "normalize.css";
import "leaflet/dist/leaflet.css";
import './styles/app.scss';
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable";
import App from './app';
import { BrowserRouter as Router, Route } from "react-router-dom";
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <Router>
      <Route path="/" component={App}/>
    </Router>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
