import * as React from "react";
import * as request from "superagent";
import Map from "./components/map";
import Draggable from 'react-draggable';

export class App extends React.Component<any, any> {

  _popup= null;
  _loaderOverlay = null;

  state = {
    loading: true,
    appLoaded: false,
    error: false,
    demographics: [],
    programFilterOpen: false,
    minStudentsDisplay: 1,
    overlayHidden: false,
    programFilterPosition: {x: 0, y: 0},
    maxStudents: 0,
    totalStudents: 0,
    currentQuarter: '',
    locations: {},
    quarters: [],
    filters: {
      minStudents: 1,
      programs: {}
    }
  }

  constructor(props) {
    super(props);
    this.handleMapLoad = this.handleMapLoad.bind(this);
  }

  // Gets new set of data based on quarter
  handleChangeQuarter(value: string) {
    this.setState({loading: true});
    this.getDataByQuarter(value);
    this.setState({currentQuarter: value});
  }

  // Gets the curriculum by date from the server
  getDataByQuarter(quarter: string) {
    request.get(`//${process.env.REACT_APP_BASE_URL}/curriculums/?quarter=${quarter}`)
    .then(res => {
      this.setState({
        locations: JSON.parse(res.text).locations,
        filters: {...this.state.filters, programs: JSON.parse(res.text).programs},
        totalStudents: JSON.parse(res.text).totalStudents,
        maxStudents: JSON.parse(res.text).maxStudents
      });
    })
    .catch(err => {
      this.setState({error: true});
    });
  }

  // Gets available quarters to display in dropdown list
  getQuarters() {
    request.get(`//${process.env.REACT_APP_BASE_URL}/quarters/`)
    .then(res => {
      const parsedQuarters = JSON.parse(res.text)
      this.setState({quarters: parsedQuarters});
      if (parsedQuarters.hasOwnProperty('length') && parsedQuarters.length > 0){
        this.handleChangeQuarter(parsedQuarters[0].date);
      }
    })
    .catch(err => {
      this.setState({error: true});
    });
  }

  // Displays/hides locations based on programs in that location
  handleUpdateProgramFilter(e, programKey) {
    const { filters: { minStudents, programs }, locations, minStudentsDisplay } = this.state;
    const newPrograms = Object.assign({}, programs);
    const newLocations = Object.assign({}, locations);
    let newTotal = this.state.totalStudents;
    let maxStudents = 0;
    let newMinDisplay = minStudentsDisplay;
    let newMinStudents = minStudents;

    newPrograms[programKey].checked = e.target.checked;

    Object.keys(newLocations).forEach(locationKey => {
      const location = newLocations[locationKey];
      let newTotalLocation = location.totalStudents;

      // If location has the program
      if (newLocations[locationKey].programs[programKey]) {
        newLocations[locationKey].programs[programKey].checked = e.target.checked;
        // If checking
        if (e.target.checked) {
          newTotal += location.programs[programKey].totalStudents;
          newTotalLocation += location.programs[programKey].totalStudents;
        }
        // If unchecking
        else {
          newTotal -= location.programs[programKey].totalStudents;
          newTotalLocation -= location.programs[programKey].totalStudents;
        }
      }

      // Track location with most students
      if (newTotalLocation > maxStudents) {
        maxStudents = newTotalLocation;
      }

      newLocations[locationKey].totalStudents = newTotalLocation;
    });

    // Fix min student display (number that appears above the range slider) {
    // In case the new max location is less than the curent min students
    if (maxStudents < minStudentsDisplay) {
      newMinDisplay = maxStudents;
      newMinStudents = maxStudents;
    }

    this.setState({
      filters: { minstudents: newMinStudents, programs: newPrograms },
      locations: newLocations,
      totalStudents: newTotal,
      maxStudents,
      minStudentsDisplay: newMinDisplay
    });
  }

  // Selects or clears all programs
  handleUpdateAllProgramFilters(type: "select"|"clear") {
    const { filters: { programs }, locations, minStudentsDisplay } = this.state;
    let newPrograms = Object.assign({}, programs);
    const newLocations = Object.assign({}, locations);
    let newTotal = 0;
    let maxStudents = 0;
    let newMinDisplay = minStudentsDisplay;

    Object.keys(newPrograms).forEach(id => {
      newPrograms[id].checked = type === "select" ? true : false;
    });

    Object.keys(newLocations).forEach(locationKey => {
      let location = newLocations[locationKey];
      let newTotalLocation = 0;
      
      if (type === "select") {
        Object.keys(location.programs).forEach(programKey => {
          newTotalLocation += location.programs[programKey].totalStudents;
          newLocations[locationKey].programs[programKey].checked = true;
        });
        newTotal += newTotalLocation;
      }

      else {
        Object.keys(location.programs).forEach(programKey => {
          newLocations[locationKey].programs[programKey].checked = false;
        });
        newMinDisplay = 0;
      }

      newLocations[locationKey].totalStudents = newTotalLocation;
      
      if (newTotalLocation > maxStudents) {
        maxStudents = newTotalLocation;
      }
      
    });

    this.setState({
      filters: { minStudents: 0, programs: newPrograms },
      locations: newLocations,
      totalStudents: newTotal,
      maxStudents,
      minStudentsDisplay: newMinDisplay
    });
  }

  // Turns the load animation off when called
  handleMapLoad() {
    this.setState({loading: false});
  }

  componentDidUpdate(prevProps, prevState) {
    const { filters, locations, programFilterOpen, loading } = this.state;

    if (!loading) {

      if (prevState.filters.minStudents !== filters.minStudents) {
        let newLocations = Object.assign({}, locations);
  
        Object.keys(newLocations).forEach(key => {
          let location = newLocations[key];
          // Number of sudents filter was updated
          if (prevState.filters.minStudents !== filters.minStudents) {
            if (location.totalStudents < filters.minStudents) {
              newLocations[key].inStudentRange = false;
            }
            if (location.totalStudents >= filters.minStudents) {
              newLocations[key].inStudentRange = true;
            }
          } 
        });
        this.setState({locations: newLocations});
      }
    }
    
    // Update program filter _popup position
    if (!prevState.programFilterOpen && programFilterOpen) {
      this.setState({
        programFilterPosition: {
          x: (window.innerWidth/2) - (this._popup.offsetWidth/2),
          y: (window.innerHeight/2) - (this._popup.offsetHeight/2)
        }
      });
    }

    // Set overlay style to fade in and out
    if (!prevState.loading && loading && this._loaderOverlay) {
      this._loaderOverlay.style.display = "block";
    }

    if (prevState.loading && !loading && this._loaderOverlay) {
      setTimeout(() => {
        this._loaderOverlay.style.display = "none";
      }, 250);
    }

  }

  componentDidMount() {
   
    this.setState({loading: true});
    this.getQuarters();
    this.setState({loading: false, appLoaded: true});
  }

  render() {
    const { 
      filters, 
      locations, 
      minStudentsDisplay, 
      programFilterOpen,
      overlayHidden,
      programFilterPosition,
      totalStudents,
      maxStudents,
      quarters,
      currentQuarter,
      loading
    } = this.state;

    return (
      <div className="App">
        <div className="filters leaflet-popup-content-wrapper" onClick={(e) => e.preventDefault()}>
          <span>Showing locations with at least <span className="current-min-students">{minStudentsDisplay}</span> students</span>
          <div className="slide-container">
            <input 
              type="range" 
              min="1" 
              max={maxStudents} 
              value={minStudentsDisplay} 
              className="slider" 
              onChange={(e) => this.setState({minStudentsDisplay: parseInt(e.target.value)})}
              onMouseUp={(e) => this.setState({filters: {...filters, minStudents: minStudentsDisplay}})}
            />
          </div>

          <select value={currentQuarter} onChange={(e) => this.handleChangeQuarter(e.target.value)}>
            {quarters.map(quarter => {
              return (
                <option key={quarter.id} value={quarter.date}>
                  {quarter.date} - {quarter.totalStudents} Students
                </option>
              );
            })}
          </select>
          
          <br/>

          <a 
            onClick={() => this.setState({programFilterOpen: true})}>
            Filter by Program
          </a>
        </div>

        {programFilterOpen && !overlayHidden ? 
          // Hide program filter popup if overlay is clicked
          <div className="overlay" onClick={() => this.setState({programFilterOpen: false})}/> 
        : null}
  
        
        <div 
          className="overlay" 
          ref={(e) => this._loaderOverlay = e}
          style={{opacity: loading ? .4 : 0}}>
          <div className="loader"/>
        </div> 

        <Draggable handle=".drag-handle" bounds="body">
          <div 
            ref={(e) => this._popup = e}
            className="program-filters leaflet-popup-content-wrapper" 
            style={{
              opacity: programFilterOpen ? 1 : 0, 
              left: programFilterPosition.x,
              top: programFilterPosition.y,
              zIndex: programFilterOpen ? 1000 : 0
            }}>
            {/* Hide overlay if user moves program filter popup */}
            <div className="drag-handle" onMouseDown={() => this.setState({overlayHidden: true})}>
              <img src="/drag-icon.svg"/>
            </div>
            <h3>Filter by Program</h3>
            <div className="total-students">Total Number of Students: {totalStudents}</div>
            <form>
              <ul>
                {Object.keys(filters.programs).map(key => {
                  const program = filters.programs[key];
                  return (
                    <li key={key}>
                      <div>
                        <input 
                          onChange={(e) => this.handleUpdateProgramFilter(e, key)} 
                          type="checkbox" 
                          name="vehicle" 
                          value={key} 
                          checked={program.checked}/> 
                        <div className="program-name">{program.name}</div>
                      </div>
                      <div className="program-total-students">{program.totalStudents} students</div>
                    </li>
                  );
                })}
              </ul>
            </form>
            
            <button onClick={() => this.handleUpdateAllProgramFilters("select")}>Select all</button>
            <button onClick={() => this.handleUpdateAllProgramFilters("clear")}>Clear all</button>

            <a 
              href="#" 
              className="leaflet-popup-close-button" 
              onClick={() => this.setState({programFilterOpen: false, overlayHidden: false})}>
              ×
            </a>
          </div>
        </Draggable>        
        <Map locations={locations} mapHasLoaded={this.handleMapLoad}/>
      </div>
    );
  }
}

export default App;
